import React, { Fragment, useEffect, useState } from "react";
import { Link, useIntl, FormattedMessage, FormattedHTMLMessage, navigate } from "gatsby-plugin-intl";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import api from "../../service/api";
import * as constants from "../../app.constant";
import alertStore from "../../store/alertStore"
import usePrevious from "../../hooks/use-previous";
import useIsMount from "../../hooks/use-is-mount";
import { sanitizeError, internationalMobilePrefix, errorKeyNavigateSystemErrorPage, getCurrentTimestamp } from "../../app.utils";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import planStore from "../../store/planStore";
import deviceStore from "../../store/deviceStore"
import customerStore from "../../store/customerStore";
import timestampStore from "../../store/timestampStore";
import AppLayout from "../../components/shared/AppLayout";
import SelectRadioField from "../../components/form/select-radio-field";
import AppStepsIndicator from "../../components/shared/AppStepsIndicator";
import AppSmsModal from "../../components/shared/AppSmsModal";
import InputField from "../../components/form/input-field";
import CheckboxField from "../../components/form/checkbox-field";
import SelectField from "../../components/form/select-field";

const PageReviewOrder = () => {
    const planType = planStore.planType;
    const isPremiumPlan = planType === constants.PLAN.PREMIUM;
    const routes08Tnc = isPremiumPlan ? constants.ROUTES.TNC08_PREMIUM : constants.ROUTES.TNC08_LITE;
    const [showSMSModal, setShowSMSModal] = useState(false);
    const isMount = useIsMount();
    const [responseOtpId, setResponseOtpId] = useState("");
    const intl = useIntl();
    const messages = intl.messages;

    const errors = {
        customerCity: messages["reviewOrder.form.cityField.required"],
        customerArea: messages["reviewOrder.form.areaField.required"],
        customerStreetAddress: messages["reviewOrder.form.streetAddressField.required"],
        referralCode: messages["reviewOrder.form.referralCodeField.required"],
        occupation: messages["reviewOrder.form.occupationField.required"],
        incomeSource: messages["reviewOrder.form.incomeSourceField.required"],
        tnc06: messages["reviewOrder.form.tnc.documents.0.required"],
        tnc07: messages["reviewOrder.form.tnc.documents.1.required"],
        tnc08: messages["reviewOrder.form.tnc.documents.2.required"],
        tncAgreedAllReviewOrder: messages["reviewOrder.form.tnc.requiredAgreeAll"],
    };

    const formik = useFormik({
        initialValues: {
            customerStreetAddress: customerStore.customerStreetAddress || "",
            customerPostalCode: customerStore.customerPostalCode || "",
            customerCity: customerStore.customerCity || "",
            customerArea: customerStore.customerArea || "",
            referralCode: "",
            tnc06: false,
            tnc07: false,
            tnc08: false,
            tncAgreedAllReviewOrder: false,
            incomeSource: "",
            acknowledgement01: "",
            acknowledgement02: "",
            acknowledgement03: "",
            occupation: "",
        },
        validationSchema: Yup.object({
            customerCity: Yup.string().required(errors.customerCity),
            customerArea: Yup.string().required(errors.customerArea),
            customerStreetAddress: Yup.string().min(5, errors.customerStreetAddress).required(errors.customerStreetAddress),
            referralCode: Yup.string().min(4, errors.referralCode).max(8, errors.referralCode),
            occupation: Yup.string().required(errors.occupation),
            incomeSource: Yup.string().required(errors.incomeSource),
            acknowledgement01: Yup.string().required(),
            acknowledgement02: Yup.string().required(),
            acknowledgement03: Yup.string().required(),
            tnc06: Yup.boolean().oneOf([true], errors.tnc06).required(),
            tnc07: Yup.boolean().oneOf([true], errors.tnc07).required(),
            tnc08: Yup.boolean().oneOf([true], errors.tnc08).required(),
            tncAgreedAllReviewOrder: Yup.boolean().oneOf([true], errors.tncAgreedAllReviewOrder).required(),
        }),
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            customerStore.updateCustomerDetailReviewOrder(values);
            onHandleRequestOtp();
        },
    });
    const previousFormikValues = usePrevious(formik.values);

    const onHandleRequestOtp = async () => {
        try {
            const payload = {
                phoneNumber: internationalMobilePrefix(customerStore.customerMobileNumber),
                countryCode: "TW",
                languageCode: "zh",
            };
            const response = await api.post.requestOtp(payload);
            setResponseOtpId(response.data.otpId);
            openModal();
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
        }
    }

    const onHandleIsWearablesPath = (deviceType) => {
        if (deviceType === "Watch") {
            return constants.ROUTES.VERIFY_WEARABLES_PHOTO;
        } else {
            return constants.ROUTES.VERIFY_DEVICE_PHOTO;
        }
    }

    const onHandleInitiateOrder = async (values, enableSubmit) => {
        const dateFormat = "YYYY-MM-DD";
        const timeFormat = "T00:00:00.000+0000";
        
        try {
            const payload = {
                otpId: responseOtpId,
                otpCode: values.otpInput,
                planId: deviceStore.devicePlanId,
                orderNumber: deviceStore.deviceOrderNumberFailed,
                deviceType:  deviceStore.deviceType,
                contact: {
                    ids: [
                        {
                            number: customerStore.customerNationalId,
                            type: "id",
                        }
                    ],
                    firstName: customerStore.customerFullName,
                    gender: customerStore.customerNationalId.charAt(1) === "1" ? "male": "female",
                    dateOfBirth: moment(customerStore.customerDateOfBirth, constants.DATE_FORMAT).format(dateFormat) + timeFormat,
                    addresses: [
                        {
                            street: customerStore.customerStreetAddress,
                            district: customerStore.customerArea,
                            area: customerStore.customerCity,
                            zipCode: customerStore.customerPostalCode,
                        }
                    ],
                    email: customerStore.customerEmail,
                    phoneNumber: customerStore.customerResidentialNumber,
                },
                promoSelected: false,
                phoneNumberAreaCode: customerStore.customerAreaCode,
                mobile: customerStore.customerMobileNumber,
                occupation: parseInt(customerStore.occupation),
                sourceOfIncome: parseInt(customerStore.incomeSource),
                deviceModel: deviceStore.deviceModel,
                deviceId: deviceStore.deviceImei,
                deviceIdType: deviceStore.deviceIdType,
                purchaseDate: moment(deviceStore.devicePurchaseDate, constants.DATE_FORMAT).format(dateFormat) + timeFormat,
                deviceRecommendedRetailPrice: deviceStore.devicePrice,
                paymentMethod: {
                    type: "creditCard",
                    details: {
                        proposerId: customerStore.customerNationalId,
                        insurantId: customerStore.customerNationalId,
                        proposerName: customerStore.customerFullName,
                        insurantName: customerStore.customerFullName,
                    }
                },
                channel: {
                    channel: "frontend"
                },
                successReturnUrl : `${process.env.SEC_HOSTING_URL}${onHandleIsWearablesPath(deviceStore.deviceType)}`,
                failReturnUrl : `${process.env.SEC_HOSTING_URL}${constants.ROUTES.ACTIVATION_UNSUCCESSFUL}`,
                personalDataCollectionAndUseDate: timestampStore.tncAgreed,
                agreeToAllTermsDate: timestampStore.tncAgreedAllAccount,
                personalDataProtectionDate: timestampStore.tnc06,
                writtenAnalysisReportDate: timestampStore.tnc07,
                policyTermsDate: timestampStore.tnc08,
                acceptedDocumentRequirementsDate: timestampStore.tncAgreedAllReviewOrder,
                fitnessAnswer: formik.values.acknowledgement01,
                confirmPremiumAnswer: formik.values.acknowledgement02,
                validContractAnswer: formik.values.acknowledgement03,
                planType: planType,
            };
            const response = await api.post.initiateOrder(payload);
            deviceStore.updateDeviceOrderNumber(response.data.orderNumber);
            window.location.href= response.data.paymentInstructions.payload;
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }

            if(enableSubmit) {
                enableSubmit();
            }
        }
    }

    const cityOptions = constants.POSTAL_AREA_BY_CITY.map((obj) => ({ label: obj.city, value: obj.city }));
    const filterByChosenCustomerCity = constants.POSTAL_AREA_BY_CITY.filter((obj) => obj.city === formik.values.customerCity);
    const areaOptions = filterByChosenCustomerCity[0] && filterByChosenCustomerCity[0].areas.map((obj) => ({ label: obj.area, value: obj.area }));

    const occupationOptions = [
        {
            label: messages["reviewOrder.form.occupationField.options.0.label"],
            value: "0",
        },
        {
            label: messages["reviewOrder.form.occupationField.options.1.label"],
            value: "1",
        },
    ];

    const incomeSourceOptions = [
        {
            label: messages["reviewOrder.form.incomeSourceField.options.0.label"],
            value: "0",
        },
        {
            label: messages["reviewOrder.form.incomeSourceField.options.1.label"],
            value: "1",
        },
        {
            label: messages["reviewOrder.form.incomeSourceField.options.2.label"],
            value: "2",
        },
    ];

    const acknowledgement01Options = [
        {
            label: messages["reviewOrder.form.acknowledgement01Field.options.0.label"],
            value: "Yes",
        },
        {
            label: messages["reviewOrder.form.acknowledgement01Field.options.1.label"],
            value: "No",
        },
    ];

    const acknowledgement02Options = [
        {
            label: messages["reviewOrder.form.acknowledgement02Field.options.0.label"],
            value: "Yes",
        },
        {
            label: messages["reviewOrder.form.acknowledgement02Field.options.1.label"],
            value: "No",
        },
    ];

    const acknowledgement03Options = [
        {
            label: messages["reviewOrder.form.acknowledgement03Field.options.0.label"],
            value: "Yes",
        },
        {
            label: messages["reviewOrder.form.acknowledgement03Field.options.1.label"],
            value: "No",
        },
    ];

    const closeModal = () => {
        setShowSMSModal(false);
        formik.setSubmitting(false);
    };

    const openModal = () => {
        setShowSMSModal(true);
    };

    useEffect(() => {
        if(!previousFormikValues) return;

        const customerCityChanged = previousFormikValues.customerCity !== formik.values.customerCity;

        if(customerCityChanged) {
            formik.setFieldValue("customerArea", "");
            formik.setFieldValue("customerPostalCode", "");
        }
    }, [previousFormikValues, formik]);

    useEffect(() => {
        if(!previousFormikValues) return;
        
        const customerAreaChanged = previousFormikValues.customerArea !== formik.values.customerArea;

        if(customerAreaChanged) {

            if (formik.values.customerArea && formik.values.customerCity) {
                const filterByChosenCustomerCity = constants.POSTAL_AREA_BY_CITY.filter(obj => obj.city === formik.values.customerCity);
                const filterBasedOnChosenCityAndArea = filterByChosenCustomerCity[0] && filterByChosenCustomerCity[0].areas.filter(obj => obj.area === formik.values.customerArea);
                const correspondingPostalCode = filterBasedOnChosenCityAndArea[0] && filterBasedOnChosenCityAndArea[0].postalCode;

                formik.setFieldValue("customerPostalCode", correspondingPostalCode);
            }

        }
        
    }, [previousFormikValues, formik]);

    useEffect(() => {
        if (formik.values.tnc06) {
            timestampStore.updateTnc06Timestamp(getCurrentTimestamp());
        }
    }, [formik.values.tnc06]);

    useEffect(() => {
        if (formik.values.tnc07) {
            timestampStore.updateTnc07Timestamp(getCurrentTimestamp());
        }
    }, [formik.values.tnc07]);

    useEffect(() => {
        if (formik.values.tnc08) {
            timestampStore.updateTnc08Timestamp(getCurrentTimestamp());
        }
    }, [formik.values.tnc08]);

    useEffect(() => {
        if (formik.values.tncAgreedAllReviewOrder) {
            timestampStore.updateTncAgreedAllReviewOrderTimestamp(getCurrentTimestamp());
        }
    }, [formik.values.tncAgreedAllReviewOrder]);

    useEffect(() => {
        if (isMount) {
            !planType && navigate(constants.ROUTES.PLANS);
        }
    }, [isMount, planType]);

    return (
        <Fragment>

            {showSMSModal && (
                <AppSmsModal
                    requestOtp={onHandleRequestOtp}
                    closeModal={closeModal}
                    onSubmit={onHandleInitiateOrder}
                />
            )}

            <AppLayout
                hasHeader={true}
                hasHeaderLogo={true}
                isBackBtn={true}
                hasFooter={true}
                prevUrl={constants.ROUTES.ACCOUNT}
            >
                <AppStepsIndicator step="3" />

                <div className="app-page page-review-order">

                    <div className="review-order">

                        <div className="review-order__container container">

                            <form
                                className="review-order__form"
                                noValidate
                                autoComplete="off"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="form-row">
                                    <div className="col-6">
                                        <SelectField
                                            label={messages["reviewOrder.form.cityField.label"]}
                                            name="customerCity"
                                            options={cityOptions}
                                            formik={formik}
                                            placeholder={messages["reviewOrder.form.cityField.placeholder"]}
                                        />
                                    </div>
                                    {formik.values.customerCity && (
                                        <div className="col-6">
                                            <SelectField
                                                label={messages["reviewOrder.form.areaField.label"]}
                                                name="customerArea"
                                                options={areaOptions}
                                                formik={formik}
                                                placeholder={messages["reviewOrder.form.areaField.placeholder"]}
                                            />
                                        </div>
                                    )}
                                </div>

                                {formik.values.customerPostalCode && (
                                    <div className="form-row">
                                        <div className="col-6">
                                            <InputField
                                                disabled={true}
                                                label={messages["reviewOrder.form.postalCodeField.label"]}
                                                placeholder={messages["reviewOrder.form.postalCodeField.placeholder"]}
                                                name="customerPostalCode"
                                                formik={formik}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="form-row">
                                    <div className="col-12">
                                        <InputField
                                            label={messages["reviewOrder.form.streetAddressField.label"]}
                                            placeholder={messages["reviewOrder.form.streetAddressField.placeholder"]}
                                            name="customerStreetAddress"
                                            formik={formik}
                                        />
                                    </div>
                                </div>

                                <h2 className="review-order__title text-bold">
                                    <FormattedMessage id="reviewOrder.form.headers.1.label" />
                                </h2>

                                <div className="form-row">
                                    <div className="col-12">
                                        <SelectField
                                            label={messages["reviewOrder.form.occupationField.label"]}
                                            name="occupation"
                                            options={occupationOptions}
                                            formik={formik}
                                            placeholder={messages["reviewOrder.form.occupationField.placeholder"]}
                                        />
                                    </div>
                                </div>

                                {formik.values.occupation === occupationOptions[1].value ?
                                    <div className="review-order__occupation-note">
                                        <FormattedMessage id="reviewOrder.form.occupationField.occupationList" />
                                    </div>
                                : null}

                                <div className="form-row">
                                    <div className="col-12">
                                        <SelectField
                                            label={messages["reviewOrder.form.incomeSourceField.label"]}
                                            name="incomeSource"
                                            options={incomeSourceOptions}
                                            formik={formik}
                                            placeholder={messages["reviewOrder.form.incomeSourceField.placeholder"]}
                                        />
                                    </div>
                                </div>

                                <h2 className="review-order__title text-bold">
                                    <FormattedMessage id="reviewOrder.form.headers.3.label" />
                                </h2>

                                <h3 className="review-order__subtitle text-bold">
                                    <FormattedMessage id="reviewOrder.form.subtitle.0.label" />
                                </h3>

                                <div className="form-row">
                                    <div className="col-12">
                                        <SelectRadioField
                                            labelHtml={<FormattedHTMLMessage id="reviewOrder.form.acknowledgement01Field.label" />}
                                            name="acknowledgement01"
                                            options={acknowledgement01Options}
                                            formik={formik}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-12">
                                        <SelectRadioField
                                            labelHtml={<FormattedHTMLMessage id="reviewOrder.form.acknowledgement02Field.label" />}
                                            name="acknowledgement02"
                                            options={acknowledgement02Options}
                                            formik={formik}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-12">
                                        <SelectRadioField
                                            labelHtml={<FormattedHTMLMessage id="reviewOrder.form.acknowledgement03Field.label" />}
                                            name="acknowledgement03"
                                            options={acknowledgement03Options}
                                            formik={formik}
                                            popoverContent={messages["reviewOrder.form.acknowledgement03Field.popoverContent"]}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-12">
                                        <InputField
                                            label={messages["reviewOrder.form.referralCodeField.label"]}
                                            name="referralCode"
                                            formik={formik}
                                        />
                                    </div>
                                </div>

                                <div className="review-order__purchase">
                                    <h2 className="review-order__title text-bold">
                                        <FormattedMessage id="reviewOrder.form.headers.2.label" />
                                    </h2>

                                    {/* Device info */}
                                    <div className="review-order__device-info">
                                        <p className="review-order__device-title">
                                            <FormattedMessage id="reviewOrder.form.deviceDetails.title" />
                                        </p>
                                        <div className="review-order__device-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.deviceDetails.details.0.subject" />
                                            </div>
                                            <div>{deviceStore.deviceModel}</div>
                                        </div>
                                        <div className="review-order__device-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.deviceDetails.details.1.subject" />
                                            </div>
                                            <div>{deviceStore.deviceImei}</div>
                                        </div>
                                    </div>

                                    {/* Plan info */}
                                    <div className="review-order__plan-info">
                                        <div className="review-order__plan-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.planDetails.details.0.subject" />
                                            </div>
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.planDetails.details.0.value" />
                                            </div>
                                        </div>
                                        <div className="review-order__plan-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.coverage.title" />
                                            </div>
                                        </div>
                                        <table className="review-order__table table table-bordered text-bold">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <FormattedMessage id={`reviewOrder.coverage.table.${planType}.0.subject`} />
                                                    </td>
                                                    <td rowSpan="2">
                                                        <FormattedMessage id={`reviewOrder.coverage.table.${planType}.0.value`} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <FormattedMessage id={`reviewOrder.coverage.table.${planType}.1.subject`} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Customer info */}
                                    <div className="review-order__customer-info">
                                        <p className="review-order__device-title text-bold">
                                            <FormattedMessage id="reviewOrder.form.personalDetails.title" />
                                        </p>
                                        <div className="review-order__customer-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.personalDetails.details.0.subject" />
                                            </div>
                                            <div>{customerStore.customerFullName}</div>
                                        </div>
                                        <div className="review-order__customer-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.personalDetails.details.1.subject" />
                                            </div>
                                            <div>{customerStore.customerNationalId}</div>
                                        </div>
                                        <div className="review-order__customer-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.personalDetails.details.2.subject" />
                                            </div>
                                            <div>{customerStore.customerDateOfBirth}</div>
                                        </div>
                                        <div className="review-order__customer-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.personalDetails.details.3.subject" />
                                            </div>
                                            <div>{customerStore.customerMobileNumber}</div>
                                        </div>
                                        <div className="review-order__customer-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.personalDetails.details.4.subject" />
                                            </div>
                                            <div>{customerStore.customerAreaCode}{customerStore.customerResidentialNumber}</div>
                                        </div>
                                        <div className="review-order__customer-details">
                                            <div>
                                                <FormattedMessage id="reviewOrder.form.personalDetails.details.5.subject" />
                                            </div>
                                            <div>{customerStore.customerEmail}</div>
                                        </div>
                                    </div>
                                </div>

                                <CheckboxField
                                    name="tnc06"
                                    formik={formik}
                                    text={[
                                        messages["reviewOrder.form.tnc.text"],
                                        " ",
                                        <Link to={constants.ROUTES.TNC06} rel="noreferrer">{messages["reviewOrder.form.tnc.documents.0.label"]}</Link>,
                                    ]}
                                />

                                <CheckboxField
                                    name="tnc07"
                                    formik={formik}
                                    text={[
                                        messages["reviewOrder.form.tnc.text"],
                                        " ",
                                        <Link to={constants.ROUTES.TNC07} rel="noreferrer">{messages["reviewOrder.form.tnc.documents.1.label"]}</Link>,
                                    ]}
                                />

                                <CheckboxField
                                    name="tnc08"
                                    formik={formik}
                                    text={[
                                        messages["reviewOrder.form.tnc.text"],
                                        " ",
                                        <Link to={routes08Tnc} rel="noreferrer">{messages["reviewOrder.form.tnc.documents.2.label"]}</Link>,
                                    ]}
                                />

                                <CheckboxField
                                    name="tncAgreedAllReviewOrder"
                                    formik={formik}
                                    text={messages["reviewOrder.form.tnc.agreeAll"]}
                                />

                                <p className="btn-container btn-container--center">
                                    <button className="btn btn-lg btn-primary" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                        <FormattedMessage id="reviewOrder.form.cta" />
                                    </button>
                                </p>

                            </form>

                        </div>

                    </div>

                </div>

            </AppLayout>

        </Fragment>
    );
};

export default PageReviewOrder;
